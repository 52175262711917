import { waitForTransactionReceipt, writeContract } from '@wagmi/core'
import { ethers } from 'ethers'
import type { Address } from 'viem'
import { BRIDGE_MIN_GAS_LIMIT_ETH } from 'constants/network'
import { wagmiConfig } from 'lib/wagmi'
import { getDepositOrWithdrawETHArguments } from 'utils/bridge/getDepositOrWithdrawETHArguments'
import { getDepositOrWithdrawETHFunctionName } from 'utils/bridge/getDepositOrWithdrawETHFunctionName'
import { getSmartContracts } from 'utils/bridge/getSmartContracts'

export const withdrawETH = async ({
  amount,
  account,
  setPendingTransactionHash,
  customReceivingAddress,
}: {
  amount: string
  account: Address
  setPendingTransactionHash: React.Dispatch<React.SetStateAction<Address | null>>
  customReceivingAddress?: Address
}) => {
  const { L2StandardBridge, L2StandardBridgeProxy } = getSmartContracts()

  const isSameReceivingAddress = !customReceivingAddress || customReceivingAddress === account

  const withdrawETHHash = await writeContract(wagmiConfig, {
    address: L2StandardBridgeProxy.address,
    abi: L2StandardBridge.abi,
    functionName: getDepositOrWithdrawETHFunctionName(isSameReceivingAddress),
    // @ts-expect-error - Union type
    args: getDepositOrWithdrawETHArguments(isSameReceivingAddress, {
      to: customReceivingAddress ?? account,
      minGasLimit: BRIDGE_MIN_GAS_LIMIT_ETH,
      extraData: '0x',
    }),
    value: ethers.parseEther(amount),
  })

  setPendingTransactionHash(withdrawETHHash)

  const tx = await waitForTransactionReceipt(wagmiConfig, { hash: withdrawETHHash })

  return tx
}
