import { getChainId, type waitForTransactionReceipt } from '@wagmi/core'
import type { Address } from 'viem'
import { useAccount } from 'wagmi'
import { WEB3_RESPONSE_ERROR_PREFIX } from 'constants/common'
import { ETH, L1_LAYER_IDS } from 'constants/network'
import type { Erc20TokenAsset } from 'hooks/useAssets'
import { useToast } from 'hooks/useToast'
import { wagmiConfig } from 'lib/wagmi'
import { useAssetContext } from 'providers/AssetProvider'
import { depositETH, withdrawETH, depositERC20, withdrawERC20 } from 'utils/bridge'
import { validateERC20Token } from 'utils/bridge/validateERC20Token'

type UseBridgeProps = {
  amount: string
  fromId: number
  setIsSwitchNetworkModalOpen: (value: boolean) => void
  setPendingTransactionHash: React.Dispatch<React.SetStateAction<Address | null>>
  setBridgedTransaction: React.Dispatch<
    React.SetStateAction<Awaited<ReturnType<typeof waitForTransactionReceipt>> | null>
  >
  isPendingTransactionQuarantined: boolean
  customReceivingAddress?: Address
}

export const useBridge = ({
  amount,
  fromId,
  setIsSwitchNetworkModalOpen,
  setPendingTransactionHash,
  setBridgedTransaction,
  isPendingTransactionQuarantined,
  customReceivingAddress,
}: UseBridgeProps) => {
  const { address } = useAccount()
  const toast = useToast()
  const { selectedAsset, refetchAssetsWithBalance } = useAssetContext()

  // https://ethereum.org/ml/developers/tutorials/optimism-std-bridge-annotated-code/.
  const handleBridge = async () => {
    const chainId = getChainId(wagmiConfig)

    if (chainId !== fromId) {
      setIsSwitchNetworkModalOpen(true)

      return
    }

    try {
      let tx: Awaited<ReturnType<typeof waitForTransactionReceipt>> | null = null

      if (selectedAsset.symbol === ETH.symbol) {
        tx = await (L1_LAYER_IDS.includes(fromId)
          ? depositETH({
              amount,
              account: address as Address,
              setPendingTransactionHash,
              customReceivingAddress,
            })
          : withdrawETH({
              amount,
              account: address as Address,
              setPendingTransactionHash,
              customReceivingAddress,
            }))
      } else {
        const erc20Asset = selectedAsset as Erc20TokenAsset

        const result = await validateERC20Token({
          address: address as Address,
          l1TokenAddress: erc20Asset.contract_address_l1 as Address,
          l2TokenAddress: erc20Asset.contract_address_l2 as Address,
          l2TokenAbi: JSON.parse(erc20Asset.abi_l2),
        })

        if (!result) {
          toast({
            status: 'danger',
            message: 'Target Erc20 token cannot be bridged.',
          })

          return
        }

        tx = await (L1_LAYER_IDS.includes(fromId)
          ? depositERC20({
              amount,
              selectedAsset: selectedAsset as Erc20TokenAsset,
              account: address as Address,
              setPendingTransactionHash,
              customReceivingAddress,
            })
          : withdrawERC20({
              amount,
              selectedAsset: selectedAsset as Erc20TokenAsset,
              account: address as Address,
              setPendingTransactionHash,
              customReceivingAddress,
            }))
      }

      if (!isPendingTransactionQuarantined) {
        setBridgedTransaction(tx)
      }

      await refetchAssetsWithBalance()
    } catch (error) {
      if (error instanceof Error) {
        if ('reason' in error && typeof error.reason === 'string') {
          toast({
            status: 'danger',
            message: error.reason.replace(WEB3_RESPONSE_ERROR_PREFIX, ''),
          })
          return
        }

        if (error.name === 'ContractFunctionExecutionError') {
          toast({
            status: 'danger',
            message: 'User rejected the request.',
          })
          return
        }

        toast({ status: 'danger', message: error.message })
      }
    }
  }

  return { handleBridge }
}
