import { readContract, waitForTransactionReceipt, writeContract } from '@wagmi/core'
import { ethers } from 'ethers'
import type { Address } from 'viem'
import { BRIDGE_MIN_GAS_LIMIT_ERC20 } from 'constants/network'
import type { Erc20TokenAsset } from 'hooks/useAssets'
import { wagmiConfig } from 'lib/wagmi'
import { getDepositERC20Arguments } from 'utils/bridge/getDepositERC20Arguments'
import { getDepositERC20FunctionName } from 'utils/bridge/getDepositERC20FunctionName'
import { getSmartContracts } from 'utils/bridge/getSmartContracts'
import { isUSDCToken, isWstETHToken } from 'utils/common'

export const depositERC20 = async ({
  amount,
  selectedAsset,
  account,
  setPendingTransactionHash,
  customReceivingAddress,
}: {
  amount: string
  selectedAsset: Erc20TokenAsset
  account: Address
  setPendingTransactionHash: React.Dispatch<React.SetStateAction<Address | null>>
  customReceivingAddress?: Address
}) => {
  const isWstETH = isWstETHToken(selectedAsset.symbol)
  const isUSDC = isUSDCToken(selectedAsset.symbol)
  const { L1StandardBridge, L1StandardBridgeProxy } = getSmartContracts(selectedAsset.symbol)

  const bigIntAmount = BigInt(ethers.parseUnits(amount, selectedAsset.decimals_l1).toString())

  const abi = JSON.parse(selectedAsset.abi_l1)

  const allowance = (await readContract(wagmiConfig, {
    address: selectedAsset.contract_address_l1 as Address,
    abi,
    functionName: 'allowance',
    args: [account, L1StandardBridgeProxy.address],
  })) as bigint

  if (allowance < bigIntAmount) {
    const hash = await writeContract(wagmiConfig, {
      address: selectedAsset.contract_address_l1 as Address,
      abi,
      functionName: 'approve',
      args: [L1StandardBridgeProxy.address, bigIntAmount],
    })

    await waitForTransactionReceipt(wagmiConfig, { hash })
  }

  const isSameReceivingAddress = !customReceivingAddress || customReceivingAddress === account

  const depositERC20Hash = await writeContract(wagmiConfig, {
    address: L1StandardBridgeProxy.address,
    abi: L1StandardBridge.abi,
    functionName: getDepositERC20FunctionName(isWstETH, isUSDC, isSameReceivingAddress),
    // @ts-expect-error - Union type
    args: getDepositERC20Arguments(isWstETH, isUSDC, isSameReceivingAddress, {
      contractAddressL2: selectedAsset.contract_address_l2 as Address,
      contractAddressL1: selectedAsset.contract_address_l1 as Address,
      amount: bigIntAmount,
      to: customReceivingAddress ?? account,
      minGasLimit: BRIDGE_MIN_GAS_LIMIT_ERC20,
      extraData: '0x',
    }),
  })

  setPendingTransactionHash(depositERC20Hash)

  const tx = await waitForTransactionReceipt(wagmiConfig, { hash: depositERC20Hash })

  return tx
}
