import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { readContract } from '@wagmi/core'
import { Big } from 'big.js'
import { ethers } from 'ethers'
import { encodeFunctionData } from 'viem'
import type { Address } from 'viem'
import { useAccount } from 'wagmi'
import { DEFAULT_VALUE } from 'constants/common'
import {
  BRIDGE_MIN_GAS_LIMIT_ERC20,
  BRIDGE_MIN_GAS_LIMIT_ETH,
  chainIdProviderMap,
  ETH,
  L2_LAYER_IDS,
} from 'constants/network'
import { wagmiConfig } from 'lib/wagmi'
import { useAssetContext } from 'providers/AssetProvider'
import { getDepositERC20Arguments } from 'utils/bridge/getDepositERC20Arguments'
import { getDepositERC20FunctionName } from 'utils/bridge/getDepositERC20FunctionName'
import { getDepositOrWithdrawETHArguments } from 'utils/bridge/getDepositOrWithdrawETHArguments'
import { getDepositOrWithdrawETHFunctionName } from 'utils/bridge/getDepositOrWithdrawETHFunctionName'
import { getSmartContracts } from 'utils/bridge/getSmartContracts'
import { getWithdrawERC20Arguments } from 'utils/bridge/getWithdrawERC20Arguments'
import { getWithdrawERC20FunctionName } from 'utils/bridge/getWithdrawERC20FunctionName'
import { isLsETHToken, isUSDCToken, isWstETHToken, getUSDCAdapterAddress } from 'utils/common'

type UseGetGasFeeProps = {
  amountToTransfer: string
  chainId: number
  customReceivingAddress?: Address
}

const calculateGasFee = async (provider: ethers.JsonRpcProvider, gasAmount: bigint) => {
  const { gasPrice } = await provider.getFeeData()
  const gasFeeInWei = (gasPrice ?? 0n) * gasAmount
  return ethers.formatEther(gasFeeInWei)
}

export const useGetGasFee = ({
  amountToTransfer,
  chainId,
  customReceivingAddress,
}: UseGetGasFeeProps) => {
  const { selectedAsset, selectedAssetBalance } = useAssetContext()
  const { address: addressFrom, isConnected } = useAccount()
  const address = customReceivingAddress ?? (addressFrom as Address)

  const isSameReceivingAddress = !customReceivingAddress || customReceivingAddress === addressFrom

  return useQuery({
    enabled: isConnected && new Big(amountToTransfer || 0).lte(selectedAssetBalance),
    queryKey: ['getGasFee', amountToTransfer, chainId],
    queryFn: async () => {
      const provider = new ethers.JsonRpcProvider(chainIdProviderMap[chainId])

      const isWithdraw = L2_LAYER_IDS.includes(chainId)

      // Estimate gas fee for ETH transaction
      if (selectedAsset.symbol === ETH.symbol) {
        const { L2StandardBridge, L1StandardBridge, L1StandardBridgeProxy, L2StandardBridgeProxy } =
          getSmartContracts()

        const addressTo = isWithdraw ? L2StandardBridgeProxy.address : L1StandardBridgeProxy.address

        const abi = isWithdraw ? L2StandardBridge.abi : L1StandardBridge.abi

        const gasAmount = await provider.estimateGas({
          from: addressFrom,
          to: addressTo,
          data: encodeFunctionData({
            abi,
            functionName: getDepositOrWithdrawETHFunctionName(isSameReceivingAddress),
            // @ts-expect-error - Union type
            args: getDepositOrWithdrawETHArguments(isSameReceivingAddress, {
              to: address,
              minGasLimit: BRIDGE_MIN_GAS_LIMIT_ETH,
              extraData: '0x',
            }),
          }),
          value: DEFAULT_VALUE,
        })

        const calculatedGas = await calculateGasFee(provider, gasAmount)
        return calculatedGas
      }

      const isWstETH = isWstETHToken(selectedAsset.symbol)
      const isUSDC = isUSDCToken(selectedAsset.symbol)
      const isLsETH = isLsETHToken(selectedAsset.symbol)
      const contractAddressL1 =
        'contract_address_l1' in selectedAsset ? selectedAsset.contract_address_l1 : undefined
      const contractAbiL1 = JSON.parse('abi_l1' in selectedAsset ? selectedAsset.abi_l1 : '[]')
      const contractAddressL2 =
        'contract_address_l2' in selectedAsset ? selectedAsset.contract_address_l2 : undefined
      const contractAbiL2 = JSON.parse('abi_l2' in selectedAsset ? selectedAsset.abi_l2 : '[]')

      // Estimate gas fee for deposit ERC20
      if (!isWithdraw) {
        const { L1StandardBridge, L1StandardBridgeProxy } = getSmartContracts(selectedAsset.symbol)
        const decimals =
          'decimals_l1' in selectedAsset ? selectedAsset.decimals_l1 : selectedAsset.decimals
        const bigIntAmount = ethers.parseUnits(amountToTransfer || '0', decimals)

        const allowance = (await readContract(wagmiConfig, {
          address: contractAddressL1 as Address,
          abi: contractAbiL1,
          functionName: 'allowance',
          args: [addressFrom as Address, L1StandardBridgeProxy.address],
        })) as bigint

        let gasAmount = BigInt(0)

        if (allowance < bigIntAmount) {
          gasAmount = await provider.estimateGas({
            from: addressFrom,
            to: contractAddressL1 as Address,
            data: encodeFunctionData({
              abi: contractAbiL1,
              functionName: 'approve',
              args: [L1StandardBridgeProxy.address, bigIntAmount],
            }),
            value: DEFAULT_VALUE,
          })
        } else {
          gasAmount = await provider.estimateGas({
            from: addressFrom,
            to: L1StandardBridgeProxy.address,
            data: encodeFunctionData({
              abi: L1StandardBridge.abi,
              functionName: getDepositERC20FunctionName(isWstETH, isUSDC, isSameReceivingAddress),
              // @ts-expect-error - Union type
              args: getDepositERC20Arguments(isWstETH, isUSDC, isSameReceivingAddress, {
                contractAddressL1: contractAddressL1 as Address,
                contractAddressL2: contractAddressL2 as Address,
                to: address,
                amount: bigIntAmount,
                minGasLimit: BRIDGE_MIN_GAS_LIMIT_ERC20,
                extraData: '0x',
              }),
            }),
            value: DEFAULT_VALUE,
          })
        }

        const calculatedGas = await calculateGasFee(provider, gasAmount)
        return calculatedGas
      }

      if ((isLsETH || isUSDC) && isWithdraw) {
        const { L2StandardBridge, L2StandardBridgeProxy } = getSmartContracts(selectedAsset.symbol)
        // @ts-expect-error - decimals_l2 is available for USDC and WstETH -> TS complains as union type (ETH contains only decimals)
        const decimals = selectedAsset.decimals_l2
        const bigIntAmount = ethers.parseUnits(amountToTransfer || '0', decimals)

        const allowance = (await readContract(wagmiConfig, {
          address: contractAddressL2 as Address,
          abi: contractAbiL2,
          functionName: 'allowance',
          args: [addressFrom as Address, L2StandardBridgeProxy.address],
        })) as bigint

        let gasAmount = BigInt(0)

        if (allowance < bigIntAmount) {
          gasAmount = await provider.estimateGas({
            from: addressFrom,
            to: contractAddressL2 as Address,
            data: encodeFunctionData({
              abi: contractAbiL2,
              functionName: 'approve',
              args: [
                isUSDC ? getUSDCAdapterAddress() : L2StandardBridgeProxy.address,
                bigIntAmount,
              ],
            }),
            value: DEFAULT_VALUE,
          })
        } else {
          gasAmount = await provider.estimateGas({
            from: addressFrom,
            to: L2StandardBridgeProxy.address,
            data: encodeFunctionData({
              abi: L2StandardBridge.abi,
              functionName: getWithdrawERC20FunctionName(isWstETH, isUSDC, isSameReceivingAddress),
              // @ts-expect-error - Union type
              args: getWithdrawERC20Arguments(isWstETH, isUSDC, isSameReceivingAddress, {
                contractAddressL2: contractAddressL2 as Address,
                contractAddressL1: contractAddressL1 as Address,
                to: address,
                amount: bigIntAmount,
                minGasLimit: BRIDGE_MIN_GAS_LIMIT_ERC20,
                extraData: '0x',
              }),
            }),
            value: DEFAULT_VALUE,
          })
        }

        const calculatedGas = await calculateGasFee(provider, gasAmount)
        return calculatedGas
      }

      // Estimate gas fee for withdraw ERC20
      const { L2StandardBridge, L2StandardBridgeProxy } = getSmartContracts(selectedAsset.symbol)
      const decimals =
        'decimals_l2' in selectedAsset ? selectedAsset.decimals_l2 : selectedAsset.decimals
      const bigIntAmount = ethers.parseUnits(amountToTransfer || '0', decimals)

      const gasAmount = await provider.estimateGas({
        from: addressFrom,
        to: L2StandardBridgeProxy.address,
        data: encodeFunctionData({
          abi: L2StandardBridge.abi,
          functionName: getWithdrawERC20FunctionName(isWstETH, isUSDC, isSameReceivingAddress),
          // @ts-expect-error - Union type
          args: getWithdrawERC20Arguments(isWstETH, isUSDC, isSameReceivingAddress, {
            contractAddressL2: contractAddressL2 as Address,
            contractAddressL1: contractAddressL1 as Address,
            to: address,
            amount: bigIntAmount,
            minGasLimit: BRIDGE_MIN_GAS_LIMIT_ERC20,
            extraData: '0x',
          }),
        }),
        value: DEFAULT_VALUE,
      })

      const calculatedGas = await calculateGasFee(provider, gasAmount)
      return calculatedGas
    },
    gcTime: 0,
    /**
     * Gas estimation sometimes fails on the following error:
     * ResourceMetering: too many deposits in this block
     * to fix this we need to retry the query infinitely, until it succeeds
     */
    retry: true,
  }) as UseQueryResult<string>
}
