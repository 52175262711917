import { ETH } from 'constants/network'
import { OTFS } from 'constants/otfs'
import { env } from 'env.client'

export const getAssetImageSrc = ({
  symbol,
  contract_address_l1,
  isManual,
}: {
  symbol: string
  contract_address_l1?: string
  isManual?: boolean
}) => {
  if (symbol === ETH.symbol) {
    return `/assets/${ETH.image}.svg`
  }

  if (OTFS.find((otf) => otf.symbol === symbol)) {
    return `/assets/otfs/${symbol}.svg`
  }

  if (isManual) {
    return `/assets/grayscale-eth.svg`
  }

  return `${env.NEXT_PUBLIC_ERC20_CDN_URL}/${env.NEXT_PUBLIC_NETWORK}/${contract_address_l1}.svg`
}
