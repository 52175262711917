import { Button, Flex, Image, Text, useColorMode } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import type { Address } from 'viem'
import { Alert } from 'components/Alert'
import { AmountLabel } from 'components/AmountLabel'
import { BridgeSelector } from 'components/BridgeSelector'
import { Modal } from 'components/Modal'
import {
  CHAINS,
  ETH,
  EXPECTED_ROLL_UP_DEPOSIT_TIME_IN_SECONDS,
  EXPECTED_ROLL_UP_WITHDRAWAL_TIME_IN_HOURS,
} from 'constants/network'
import { useThemeColors } from 'hooks/useThemeColors'
import { useAssetContext } from 'providers/AssetProvider'
import { getTruncatedText } from 'utils/getTruncatedText'

type Value = {
  id: number
  label: string
}

type BridgeApproveModalProps = {
  from: Value
  to: Value
  isOpen: boolean
  title: string
  onClose: () => void
  amount: string
  gasFee: number | string
  isGasFeeLoading?: boolean
  onApprove: () => Promise<void>
  isPendingTransactionQuarantined: boolean
  onIncorrectNetwork: () => void
  customReceivingAddress?: Address
}

export const BridgeApproveModal = ({
  isOpen,
  title,
  onClose,
  from,
  to,
  amount,
  gasFee,
  isGasFeeLoading,
  onApprove,
  isPendingTransactionQuarantined,
  onIncorrectNetwork,
  customReceivingAddress,
}: BridgeApproveModalProps) => {
  const COLORS = useThemeColors()
  const { colorMode } = useColorMode()
  const { t } = useTranslation(['common'])
  const { selectedAsset } = useAssetContext()
  const [isBridging, setIsBridging] = React.useState(false)

  const expectedTime =
    from.id === CHAINS.zircuit.id
      ? `~${t('common:hours', { count: EXPECTED_ROLL_UP_WITHDRAWAL_TIME_IN_HOURS })}`
      : `~${EXPECTED_ROLL_UP_DEPOSIT_TIME_IN_SECONDS} ${t('common:seconds')}`

  React.useEffect(() => {
    if (isPendingTransactionQuarantined && !isOpen) {
      setIsBridging(false)
    }
  }, [isOpen, isPendingTransactionQuarantined])

  return (
    <Modal isOpen={isOpen} title={title} onClose={onClose}>
      <Flex
        w="100%"
        justifyContent="center"
        bgColor={COLORS.grey07}
        border="1px solid"
        borderColor={COLORS.grey06}
        borderRadius={10}
        py={5}
        px={10}
      >
        <Image src={`/assets/zircuit2_${colorMode}.svg`} />
      </Flex>
      <BridgeSelector
        gap={5}
        mt={8}
        from={from}
        to={to}
        isDisabled
        onIncorrectNetwork={onIncorrectNetwork}
      />
      <AmountLabel
        mt={6}
        label={t('common:YouWillReceive')}
        value={`${amount} ${selectedAsset.symbol}`}
      />
      <AmountLabel
        mt={2}
        label={t('common:EstimatedGasFees')}
        isValueLoading={isGasFeeLoading}
        value={`${gasFee} ${ETH.symbol}`}
      />
      {customReceivingAddress && (
        <AmountLabel
          mt={2}
          label={t('common:CustomReceivingAddress')}
          value={getTruncatedText(customReceivingAddress, 10, 10)}
        />
      )}
      <Flex mt={2}>
        <Text variant="text2regular" color={COLORS.grey02}>
          {t('common:ExpectedTime')}
        </Text>
        <Text ml="auto" variant="text2medium">
          {expectedTime}
        </Text>
      </Flex>
      {from.id === CHAINS.zircuit.id && (
        <Alert mt={6} variant="warning">
          {t('common:ZircuitWithdrawalAlertMessage', {
            count: EXPECTED_ROLL_UP_WITHDRAWAL_TIME_IN_HOURS,
          })}
        </Alert>
      )}
      <Button
        mt={6}
        w="100%"
        isLoading={isBridging}
        loadingText={t('common:Bridging')}
        onClick={() => {
          const asyncFn = async () => {
            setIsBridging(true)
            await onApprove()
            setIsBridging(false)
          }

          void asyncFn()
        }}
      >
        {t('common:ApproveTransactionInYourWallet')}
      </Button>
    </Modal>
  )
}
