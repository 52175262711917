import { DECIMALS } from 'constants/common'
import { env } from 'env.client'

export const getFormattedNumber = (value: string | number) =>
  Number(value).toLocaleString(undefined, { maximumFractionDigits: 20 })

export const weiToEth = (value: string | number, decimals?: string | number) =>
  Number(value) / 10 ** Number(decimals ?? DECIMALS)

export const calculateGasFees = ({ gasUsed = 0, gasPrice = 0 }) => weiToEth(gasUsed * gasPrice)

export const isWstETHToken = (tokenName: string) =>
  (env.NEXT_PUBLIC_NETWORK === 'testnet' || env.NEXT_PUBLIC_NETWORK === 'mainnet') &&
  tokenName === 'wstETH'

export const isUSDCToken = (tokenName: string) =>
  (env.NEXT_PUBLIC_NETWORK === 'testnet' || env.NEXT_PUBLIC_NETWORK === 'mainnet') &&
  tokenName === 'USDC'

export const isLsETHToken = (tokenName: string) =>
  env.NEXT_PUBLIC_NETWORK === 'mainnet' && tokenName === 'LsETH'

export const getUSDCAdapterAddress = () => {
  if (env.NEXT_PUBLIC_NETWORK === 'mainnet') {
    return '0xFA7a02120a999067b88266CCb9Ca4D7e0E3d9f16'
  }

  if (env.NEXT_PUBLIC_NETWORK === 'testnet') {
    return '0x3aE34cF2F3225e0c6E532BF9B6579C073bE4305b'
  }

  return ''
}
