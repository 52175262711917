import { readContract, waitForTransactionReceipt, writeContract } from '@wagmi/core'
import { ethers } from 'ethers'
import type { Address } from 'viem'
import { BRIDGE_MIN_GAS_LIMIT_ERC20 } from 'constants/network'
import type { Erc20TokenAsset } from 'hooks/useAssets'
import { wagmiConfig } from 'lib/wagmi'
import { getSmartContracts } from 'utils/bridge/getSmartContracts'
import { getWithdrawERC20Arguments } from 'utils/bridge/getWithdrawERC20Arguments'
import { getWithdrawERC20FunctionName } from 'utils/bridge/getWithdrawERC20FunctionName'
import { isLsETHToken, isUSDCToken, isWstETHToken, getUSDCAdapterAddress } from 'utils/common'

export const withdrawERC20 = async ({
  amount,
  selectedAsset,
  account,
  setPendingTransactionHash,
  customReceivingAddress,
}: {
  amount: string
  selectedAsset: Erc20TokenAsset
  account: Address
  setPendingTransactionHash: React.Dispatch<React.SetStateAction<Address | null>>
  customReceivingAddress?: Address
}) => {
  const isWstETH = isWstETHToken(selectedAsset.symbol)
  const isUSDC = isUSDCToken(selectedAsset.symbol)
  const isLsETH = isLsETHToken(selectedAsset.symbol)
  const { L2StandardBridge, L2StandardBridgeProxy } = getSmartContracts(selectedAsset.symbol)

  const bigIntAmount = BigInt(ethers.parseUnits(amount, selectedAsset.decimals_l2).toString())

  const isSameReceivingAddress = !customReceivingAddress || customReceivingAddress === account

  if (isLsETH || isUSDC) {
    const abi = JSON.parse(selectedAsset.abi_l2)

    const allowance = (await readContract(wagmiConfig, {
      address: selectedAsset.contract_address_l2 as Address,
      abi,
      functionName: 'allowance',
      args: [account, L2StandardBridgeProxy.address],
    })) as bigint

    if (allowance < bigIntAmount) {
      const hash = await writeContract(wagmiConfig, {
        address: selectedAsset.contract_address_l2 as Address,
        abi,
        functionName: 'approve',
        args: [isUSDC ? getUSDCAdapterAddress() : L2StandardBridgeProxy.address, bigIntAmount],
      })

      await waitForTransactionReceipt(wagmiConfig, { hash })
    }
  }

  const withdrawERC20Hash = await writeContract(wagmiConfig, {
    address: L2StandardBridgeProxy.address,
    abi: L2StandardBridge.abi,
    functionName: getWithdrawERC20FunctionName(isWstETH, isUSDC, isSameReceivingAddress),
    // @ts-expect-error - Union type
    args: getWithdrawERC20Arguments(isWstETH, isUSDC, isSameReceivingAddress, {
      contractAddressL2: selectedAsset.contract_address_l2 as Address,
      contractAddressL1: selectedAsset.contract_address_l1 as Address,
      amount: bigIntAmount,
      to: customReceivingAddress ?? account,
      minGasLimit: BRIDGE_MIN_GAS_LIMIT_ERC20,
      extraData: '0x',
    }),
  })

  setPendingTransactionHash(withdrawERC20Hash)

  const tx = await waitForTransactionReceipt(wagmiConfig, { hash: withdrawERC20Hash })

  return tx
}
