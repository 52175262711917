import { erc20Abi } from 'viem'
import type { Abi, Address } from 'viem'
import { publicClientL1, publicClientL2 } from 'lib/viem'
import { getSmartContracts } from 'utils/bridge/getSmartContracts'
import { isWstETHToken } from 'utils/common'

const EXPECTED_ERROR_MESSAGES = {
  onlyBridgeCanMintAndBurn: 'only bridge can mint and burn',
  lidoOnlyBridgeCanMintAndBurn: 'ErrorNotBridge',
}

type ValidateTokenProps = {
  address: Address
  l1TokenAddress: Address
  l2TokenAddress: Address
  l2TokenAbi: Abi
}

/**
 *  Check whether target erc20 token is valid for bridging on our network.
 *  @param address: Connected account's address
 *  @param l1TokenAddress: L1 Address of the erc20 token
 *  @param l2TokenAddress: L2 Address of the erc20 token
 *  @param l2TokenAbi: L2 Abi of the erc20 token
 * */
export const validateERC20Token = async ({
  address,
  l1TokenAddress,
  l2TokenAddress,
  l2TokenAbi,
}: ValidateTokenProps) => {
  const symbol = (await publicClientL2.readContract({
    address: l2TokenAddress,
    abi: l2TokenAbi,
    functionName: 'symbol',
    args: [],
  })) as string

  const isWstETH = isWstETHToken(symbol)

  if (!isWstETH) {
    try {
      const remoteTokenAddressL1 = await publicClientL2.readContract({
        address: l2TokenAddress,
        abi: l2TokenAbi,
        functionName: 'remoteToken',
        args: [],
      })

      // Ensure that remoteTokenAddress on l2TokenAddress is equal to l1TokenAddress.
      if (remoteTokenAddressL1 !== l1TokenAddress) {
        return null
      }
    } catch {
      return null
    }
  }

  const l1TokenDecimals = await publicClientL1.readContract({
    address: l1TokenAddress,
    abi: erc20Abi,
    functionName: 'decimals',
  })

  const l2TokenDecimals = await publicClientL2.readContract({
    address: l2TokenAddress,
    abi: erc20Abi,
    functionName: 'decimals',
  })

  // Ensure both decimals are equal.
  if (l1TokenDecimals !== l2TokenDecimals) {
    return null
  }

  const l2Bridge = await publicClientL2.readContract({
    address: l2TokenAddress,
    abi: l2TokenAbi,
    functionName: 'bridge',
    args: [],
  })

  const { L2StandardBridgeProxy } = getSmartContracts(symbol)

  // Ensure that bridge address is set correctly.
  if (l2Bridge !== L2StandardBridgeProxy.address) {
    return null
  }

  // Ensure that only Bridge can Mint and Burn.
  try {
    await publicClientL2.simulateContract({
      account: address,
      address: l2TokenAddress,
      abi: l2TokenAbi,
      functionName: isWstETH ? 'bridgeMint' : 'mint',
      args: [address, 1],
    })

    return null
  } catch (error) {
    if (
      error instanceof Error &&
      error.message.includes(EXPECTED_ERROR_MESSAGES.onlyBridgeCanMintAndBurn)
    ) {
      // Expect error that contains `only bridge can mint and burn` inside of the error message.
    } else if (
      error instanceof Error &&
      isWstETH &&
      error.message.includes(EXPECTED_ERROR_MESSAGES.lidoOnlyBridgeCanMintAndBurn)
    ) {
      // Expect error that contains `ErrorNotBridge` inside of the error message if token is lido wstETH.
    } else {
      return null
    }
  }

  try {
    await publicClientL2.simulateContract({
      account: address,
      address: l2TokenAddress,
      abi: l2TokenAbi,
      functionName: isWstETH ? 'bridgeBurn' : 'burn',
      args: [address, 1],
    })

    return null
  } catch (error) {
    if (
      error instanceof Error &&
      error.message.includes(EXPECTED_ERROR_MESSAGES.onlyBridgeCanMintAndBurn)
    ) {
      // Expect error that contains `only bridge can mint and burn` inside of the error message.
    } else if (
      error instanceof Error &&
      isWstETH &&
      error.message.includes(EXPECTED_ERROR_MESSAGES.lidoOnlyBridgeCanMintAndBurn)
    ) {
      // Expect error that contains `ErrorNotBridge` inside of the error message if token is lido wstETH.
    } else {
      return null
    }
  }

  try {
    await publicClientL2.simulateContract({
      account: L2StandardBridgeProxy.address,
      address: l2TokenAddress,
      abi: l2TokenAbi,
      functionName: isWstETH ? 'bridgeMint' : 'mint',
      args: [L2StandardBridgeProxy.address, 1],
    })
    // Expect it to work.
  } catch {
    return null
  }

  try {
    await publicClientL2.simulateContract({
      account: L2StandardBridgeProxy.address,
      address: l2TokenAddress,
      abi: l2TokenAbi,
      functionName: isWstETH ? 'bridgeBurn' : 'burn',
      args: [L2StandardBridgeProxy.address, 1],
    })

    return null
  } catch (error) {
    // Expect error only if it doesn't contain `only bridge can mint and burn` inside of the error message.
    if (
      error instanceof Error &&
      error.message.includes(EXPECTED_ERROR_MESSAGES.onlyBridgeCanMintAndBurn)
    ) {
      return null
    }
  }

  return { l1TokenDecimals, l2TokenDecimals, symbol }
}
